@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --font-size-base: 14px;
  --font-size-small: 0.875rem;
  --font-size-medium: 0.9rem;
  --font-size-large: 1.25rem;
  --font-size-xl: 1.5rem;
  --font-size-xxl: 2rem;
}

html {
  font-size: var(--font-size-base);
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  line-height: 1.6;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
}

p {
  color: rgb(85, 85, 85);
  font-size: var(--font-size-medium);
}

a,
.btn {
  transition: all 0.3s ease;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (max-width: 768px) {
  :root {
    --font-size-base: 12px;
  }
}
