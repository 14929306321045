section {
  height: 85vh;
  margin: 0 10rem;
  box-sizing: border-box;
  min-height: fit-content;
  position: relative;
}

.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  height: 73vh;
}

.profile > .section_pic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 300px;
  margin: 0;
  overflow: hidden;
  border-radius: 50%;
}

.profile > .section_pic-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 30% 70%;
}

.section_text {
  align-self: center;
  text-align: center;
}

.section_text p {
  font-weight: 600;
}

.section_text_p1 {
  text-align: center;
  align-self: center;
}

.section_text_p2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

#socials-container {
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  margin-top: 0.8rem;
}

.icon {
  cursor: pointer;
  height: 1.8rem;
}

.btn-container {
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  margin-top: 0.8rem;
}

.btn {
  font-weight: 600;
  transition: all 0.3s ease;
  padding: 0.8rem;
  width: 8rem;
  border-radius: 1.8rem;
}

.btn-color-1,
.btn-color-2 {
  border: rgb(53, 53, 53) 0.1rem solid;
}

.btn-color-1:hover,
.btn-color-2:hover {
  cursor: pointer;
}

.btn-color-1,
.btn-color-2:hover {
  background: rgb(53, 53, 53);
  color: white;
}

.btn-color-1:hover {
  background-color: rgb(0, 0, 0);
}

.btn-color-2 {
  background: none;
}

.btn-color-2:hover {
  border: rgb(255, 255, 255) 0.1rem solid;
}

.profile-grid {
  background-image: repeating-linear-gradient(
      rgba(0, 0, 0, 1) 0 1px,
      transparent 1.5px 100%
    ),
    repeating-linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 0 1px,
      transparent 1.5px 100%
    );
  background-size: calc(10vw - 6.6px) calc(10vw - 6.6px);
  border-radius: 500px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  opacity: 0.1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -10;
}

.title {
  font-size: 2.2em;
  text-align: center;
}

.animated-title {
  position: relative;
  width: max-content;
  margin: 0 auto;
}

.animated-title::before,
.animated-title::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.animated-title::before {
  background: white; /* Adjust this to match your background color */
  animation: typewriter 4s steps(12) 1s forwards;
}

.animated-title::after {
  width: 0.125em;
  background: black;
  animation: typewriter 4s steps(12) 1s forwards, blink 750ms steps(12) infinite;
}

@keyframes typewriter {
  to {
    left: 100%;
  }
}

@keyframes blink {
  to {
    background: transparent;
  }
}
@media screen and (max-width: 1200px) {
  section {
    margin: 0 0rem;
  }
}
@media screen and (max-width: 768px) {
  .profile-grid {
    background-size: calc(20vw - 6.6px) calc(20vw - 6.6px);
    border-radius: 0;
    opacity: 0.05;
    top: 2%;
  }
}

@media screen and (max-width: 480px) {
  .profile-grid {
    background-size: calc(25vw - 6.6px) calc(25vw - 6.6px);
  }
}
@media screen and (max-width: 992px) {
  .profile {
    flex-direction: column;
    min-height: 750px;
    height: auto;
    gap: 2rem;
  }

  .profile > .section_pic-container {
    height: 300px;
    width: 300px;
  }

  .section_text_p2 {
    font-size: 1.5rem;
  }

  .title {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  section {
    margin: 0 0rem;
  }

  .profile > .section_pic-container {
    height: 250px;
    width: 250px;
  }

  .btn {
    width: 7rem;
  }
}

@media screen and (max-width: 480px) {
  .profile > .section_pic-container {
    height: 200px;
    width: 200px;
  }

  .section_text_p2 {
    font-size: 1.25rem;
  }

  .title {
    font-size: 2rem;
  }

  .btn-container {
    flex-direction: column;
    align-items: center;
  }

  .btn {
    width: 100%;
    max-width: 200px;
  }
}
@media screen and (max-width: 992px) {
  /* ... (keep existing styles) ... */

  .title,
  .animated-title {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 480px) {
  /* ... (keep existing styles) ... */

  .title,
  .animated-title {
    font-size: 2rem;
  }
}
