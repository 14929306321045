#contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70vh;
  padding: 2rem;
}

.contact-info-upper-container {
  display: flex;
  justify-content: center;
  width: 40%;
  border-radius: 2rem;
  border: rgb(163, 163, 163) 0.1rem solid;
  background: rgb(250, 250, 250);
  margin: 2rem;
  padding: 0.5rem;
}

.contact-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem;
}

.contact-info-container p {
  font-size: larger;
}

.contact-icon {
  cursor: default;
}

.email-icon {
  height: 2.5rem;
}

/* Large screens */
@media (min-width: 1400px) {
  .contact-info-upper-container {
    width: 100%;
    max-width: 600px;
  }
}

/* Medium-large screens */
@media (min-width: 992px) and (max-width: 1199px) {
  .contact-info-upper-container {
    width: 100%;
  }
}

/* Medium screens */
@media (min-width: 769px) and (max-width: 991px) {
  #contact {
    height: auto;
    min-height: 60vh;
  }

  .contact-info-upper-container {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .contact-info-container {
    width: calc(50% - 1rem);
    margin: 0.5rem;
  }
}

/* Small-medium screens */
@media (max-width: 768px) {
  #contact {
    padding: 2rem 1rem;
    height: auto;
  }

  .contact-info-upper-container {
    width: 80%;
    flex-direction: column;
    padding: 1rem;
  }

  .contact-info-container {
    width: 100%;
    margin: 1rem 0;
  }
}

/* Small screens */
@media (max-width: 480px) {
  .contact-info-upper-container {
    width: 100%;
    padding: 1rem;
  }

  .contact-info-container {
    width: 100%;
    margin: 1rem 0;
  }

  .contact-info-container p {
    font-size: medium;
  }
}
