nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 10vh; /* Reduced from 17vh */
  padding: 0 1rem;
  position: sticky;
  top: 0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-logo {
  font-size: 1.5rem; /* Reduced from 2rem */
  font-weight: 300;
}

ul {
  display: flex;
  gap: 1.5rem; /* Reduced from 2rem */
  list-style: none;
}

a,
.links {
  text-decoration: none;
  color: black;
  font-size: 1.2rem; /* Reduced from 1.5rem */
  transition: color 0.3s ease;
}

a:hover {
  color: grey;
  text-decoration: underline;
  text-underline-offset: 0.4rem; /* Reduced from 0.5rem */
  text-decoration-color: rgb(181, 181, 181);
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 768px) {
  nav {
    justify-content: space-between;
    height: 10vh; /* Further reduced for mobile */
  }

  .nav-logo {
    font-size: 1.3rem; /* Slightly reduced for mobile */
  }

  .nav-links {
    display: none;
    position: absolute;
    top: 10vh; /* Align with new nav height */
    right: 0;
    background-color: rgba(255, 255, 255, 0.95);
    text-align: right;
    z-index: 1000;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 0 10px;
  }

  .nav-links.open {
    display: block;
  }

  .nav-links ul {
    flex-direction: column;
    gap: 0.8rem; /* Reduced gap for mobile */
  }

  a {
    font-size: 1.1rem; /* Slightly reduced for mobile */
  }

  .menu-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    z-index: 1001;
  }

  .menu-icon div {
    width: 22px; /* Slightly reduced */
    height: 2px; /* Slightly reduced */
    background-color: black;
    margin: 3px 0;
    transition: 0.3s;
  }
}
